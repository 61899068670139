import React from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/fr'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { navigate } from 'gatsby'

class WorkshopsCalendar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}

    }

    createEvents() {
        let events = []
        this.props.workshops.forEach(workshop => {
            const workshopConfiguration = this.props.workshopsConfiguration[workshop.path]
            if (!workshopConfiguration) {
                return 
            }
            
            return workshopConfiguration.dates?.forEach(date => {
                if (moment(date.start) > moment())
                {
                    const key = workshop.path + '_' + date.start + '_' + date.end
 
                    const bookings = this.props.workshopsBookings[key]
                    const full = bookings && bookings >= workshopConfiguration.nbPlaces

                    events.push({
                        title: workshopConfiguration.title,
                        desc: workshop.description,
                        start: moment(date.start).toDate(),
                        end: moment(date.end).toDate(),
                        path: workshop.path,
                        full: full
                    })
                }
            })
        })
       return events
    }

    handleEventPropGetter(event, start, end, isSelected) {

        let style = {
          backgroundColor: '#4D9791',
          borderRadius: 0
        }
    
        if (event.full) {
          style['backgroundColor'] = 'red'
        }
    
        return {
          style: style
        }
      }

    render() {
        let events = this.createEvents()
        const localizer = momentLocalizer(moment)
        return (
            <Calendar
            localizer={localizer}
            events={events}
            defaultView='week'
            culture='fr'
            min={new Date(0, 0, 0, 8, 30, 0)}
            max={new Date(0, 0, 0, 21, 30, 0)}
            style={{
                height: "800px"
            }}
            eventPropGetter={this.handleEventPropGetter}
            step={15}
            messages={{ 
                date: 'Date',
                time: 'Time',
                event: 'Event',
                allDay: 'Toute le journée',
                week: 'Semaine',
                work_week: 'Semaine ouvrée',
                day: 'Jour',
                month: 'Mois',
                previous: 'Précédent',
                next: 'Suivant',
                yesterday: 'Hier',
                tomorrow: 'Demain',
                today: "Aujourd'hui",
                agenda: 'Agenda',
                noEventsInRange: 'There are no events in this range.',
                showMore: function(e) {
                return '+' + e + ' more'
            }}}
            onSelectEvent={(event)=> {navigate('/ateliers/' + event.path)}}
          />
        )
    }
}

export default WorkshopsCalendar