import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Card, Col, Jumbotron, Alert } from 'react-bootstrap'
import Img from "gatsby-image"
import ReactMarkdown from 'react-markdown'
import { getWorshopsConfiguration } from '../utils/workshops'
import WorkshopsCalender from '../components/workshopsCalendar'
import axios from 'axios'

class WorkshopsPage extends React.Component {
    constructor (props) {
        super(props)

        const { mdx, workshopsMdx } = props.data
        const { frontmatter } = mdx

        const { image_hero, birthday, description } = frontmatter

        const { workshops } = workshopsMdx

        this.state = {
            workshopsConfiguration: {},
            workshops: workshops.map(workshop => workshop.workshop),
            image_hero: image_hero,
            birthday: birthday,
            workshopsBookings: {},
            description: description,
            ready: false
        }
    }

    componentDidMount() {
        getWorshopsConfiguration().then(workshopsConfiguration => {
            this.setState({
                workshopsConfiguration: workshopsConfiguration
            })
        }).then(() => {
            axios({method: 'get', url:'/.netlify/functions/workshopsBookings'}).then((response) => {
                this.setState({
                    workshopsBookings: response.data.workshops                
                })
            })
        }).then(() => {
            this.setState({
                ready: true
            })
        })
    }

    render() {
        const workshopTypes = ["Enfants sans leurs parents", "Ateliers parent-enfant", "Ateliers jeunes parents", "Formation professionnelle"]
        console.log(this.state.workshops)
        console.log(this.state.workshopsConfiguration)

        return (
            <Layout>
                <SEO title="Liste des ateliers" />
                <Container fluid className="p-0">
                  <Jumbotron fluid style={{
                      backgroundImage: `url(${this.state.image_hero.childImageSharp.fluid.src})`,
                      backgroundSize: 'cover',
                  }}>
                      <Row  style={{
                          minHeight: "200px"
                      }} noGutters>
                          <Col className="align-self-center text-center">
                              <div>
                                  <h1 className="font-weight-bolder">Ateliers</h1>
                              </div>
                          </Col>
                      </Row>
                  </Jumbotron>
              </Container>
              
              <Container className="py-4 text-justify">
                  <h4 className="mb-4" id="workshops-list">Fonctionnement des ateliers</h4>
                  <Alert className="alert-green">
                    <ReactMarkdown parserOptions={{ commonmark: true }} source={this.state.description} />
                  </Alert>
                  {
                      this.state.ready && (
                      workshopTypes.map(workshopType => {
                          const filteredWorkshops = this.state.workshops.filter(
                              workshop => this.state.workshopsConfiguration[workshop.path] && this.state.workshopsConfiguration[workshop.path].type === workshopType
                          )

                          return(
                              <div className={"py-4"} id={workshopType}>
                                  <h4 className="mb-4" id="workshops-list">{workshopType}</h4>
                                  <Row className="row-cols-1 row-cols-md-4 w-xs-50">


                                      {
                                          filteredWorkshops.map((workshop, index) => {
                                              return (
                                                  <Col key={index} className="mt-4">
                                                      <Link to={`/ateliers/${workshop.path}`}>
                                                          <Card className="h-100">
                                                              <Img fluid={workshop.workshop_image.childImageSharp.fluid} className="card-img-top h-75"/>
                                                              <Card.Body className="h-25">
                                                                  <Card.Title className="text-center">{workshop.title}</Card.Title>
                                                              </Card.Body>
                                                          </Card>
                                                      </Link>
                                                  </Col>
                                              )
                                          })
                                      }
                                  </Row>

                              </div>
                          )
                      }))
                  }
      
                  <div id ="workshops-calendar">
                      <h4 className="my-4">Calendrier des ateliers</h4>
                      <WorkshopsCalender workshops={this.state.workshops} 
                      workshopsConfiguration={this.state.workshopsConfiguration}
                      workshopsBookings={this.state.workshopsBookings}
                      />
                  </div>  
                  
              </Container>
            </Layout>
          )
        }
}

export default WorkshopsPage

export const pageQuery = graphql`
    query($id: String) {
        mdx(id: { eq: $id }) {
            frontmatter {
                image_hero {
                    childImageSharp {
                        fluid(maxWidth: 1024, quality: 75) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                description
            }
        }
        workshopsMdx:allMdx(filter: {fileAbsolutePath: {regex: "/content\/workshops\/.*/"}}) {
            workshops:nodes {
                workshop:frontmatter {
                    description
                    title
                    path
                    workshop_image {
                        childImageSharp {
                            fluid(maxHeight: 350) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }`
