import axios from "axios"


export function getWorshopsConfiguration() {
    return axios({method: 'get', url:'/.netlify/functions/workshopsConfiguration'}).then((response) => {
        return response.data.workshops_configuration
    }).catch(err => {
        return {}
    })
}
 